// Pokemon
const filteredPokemon = {
    columns: ['Pokémon', 'Filter'],
    rows: [
        ['Eternamax Eternatus', 'Above 720 total base stats'],
        ['Mega Mewtwo (X and Y)', 'Above 720 total base stats and in-battle held item activation'],
        ['Mega Rayquaza', 'Above 720 total base stats and in-battle move activation'],
        ['Primal Kyogre', 'Above 720 total base stats and in-battle held item activation'],
        ['Primal Groudon', 'Above 720 total base stats and in-battle held item activation'],
        ['Ultra Necrozma', 'Above 720 total base stats and in-battle held item activation'],
        ['Unown', 'Below 360 total base stats'],
        ['Luvdisc', 'Below 360 total base stats'],
        ['Delibird', 'Below 360 total base stats'],
        ['Ditto', 'Below 360 total base stats'],
        ['Smeargle', 'Below 360 total base stats'],
        ['Shedinja', 'Below 360 total base stats'],
        ['Wishiwashi', 'Below 360 total base stats'],
        ['Crowned Zacian', 'In-battle held item activation'],
        ['Crowned Zamazenta', 'In-battle held item activation'],
        ['Zygarde Complete', 'In-battle ability activation'],
        ['Mega Evolutions', 'In-battle held item activation'],
        ['Dialga Origin', 'In-battle held item activation'],
        ['Palkia Origin', 'In-battle held item activation'],        
        ['Giratina Origin', 'In-battle held item activation'],        
        ['Meloetta Pirouette', 'In-battle move activation'],        
        ['Ash Greninja', 'In-battle ability activation'],   
        ['Darmanitan Zen Mode', 'In-battle ability activation'],
        ['Minior Core', 'In-battle ability activation'],                                
        ['Busted Mimikyu', 'In-battle ability activation'], 
        ['Cramorant (Gulping and Gorging)', 'In-battle ability activation'],  
        ['Eiscue Noice Face', 'In-battle ability activation'], 
        ['Hangry Morpeko', 'In-battle ability activation'],        
        ['Sunshine Cherrim', 'In-battle ability activation'],        
        ['Castform (Sunny, Rainy and Snowy)', 'In-battle ability activation'],                
    ]
}
const topPokemon = {
    columns: ['Pokémon', 'Category'],
    rows: [        
        ['Eternatus', 'Legendary'],
        ['Mewtwo', 'Legendary'],
        ['Lugia', 'Legendary'],
        ['Ho Oh', 'Legendary'],
        ['Dialga', 'Legendary'],
        ['Palkia', 'Legendary'],
        ['Giratina', 'Legendary'],
        ['Reshiram', 'Legendary'],
        ['Zekrom', 'Legendary'],
        ['Xerneas', 'Legendary'],
        ['Yveltal', 'Legendary'],        
        ['Solgaleo', 'Legendary'],
        ['Lunala', 'Legendary'],
        ['Necrozma (Default, Dusk Mane and Dawn Wings)', 'Legendary'],
        ['Calyrex (Shadow Raider and Ice Raider)', 'Legendary'],
        ['Kyogre', 'Legendary'],
        ['Groudon', 'Legendary'],
        ['Regigigas', 'Legendary'],
        ['Zacian', 'Legendary'],
        ['Zamazenta', 'Legendary'],
        ['Kyurem', 'Legendary'],  
        ['Latias (Default or Mega)', 'Legendary or mega evolution'],
        ['Latios (Default or Mega)', 'Legendary or mega evolution'],
        ['Heatran', 'Legendary'],
        ['Cresselia', 'Legendary'],
        ['Landorus (Incarnate and Therian)', 'Legendary'],
        ['Zygarde 50%', 'Legendary'],
        ['Necrozma', 'Legendary'],
        ['Articuno', 'Legendary'],
        ['Zapdos', 'Legendary'],
        ['Moltres', 'Legendary'],
        ['Raikou', 'Legendary'],
        ['Entei', 'Legendary'],
        ['Suicune', 'Legendary'],
        ['Regirock', 'Legendary'],
        ['Regice', 'Legendary'],
        ['Registeel', 'Legendary'],
        ['Uxie', 'Legendary'],
        ['Mesprit', 'Legendary'],
        ['Azelf', 'Legendary'],
        ['Cobalion', 'Legendary'],
        ['Terrakion', 'Legendary'],
        ['Virizion', 'Legendary'],
        ['Tornadus (Incarnate and Therian)', 'Legendary'],
        ['Thundurus (Incarnate and Therian)', 'Legendary'],
        ['Regieleki', 'Legendary'],
        ['Regidrago', 'Legendary'],
        ['Glastrier', 'Legendary'],
        ['Spectrier', 'Legendary'],        
        ['Silvally', 'Legendary'],
        ['Tapu Koko', 'Legendary'],
        ['Tapu Lele', 'Legendary'],
        ['Tapu Bulu', 'Legendary'],
        ['Tapu Fini', 'Legendary'],
        ['Urshifu (Single Strike and Rapid Strike)', 'Legendary'], 
        ['Arceus', 'Mythical'],
        ['Hoopa (Unbound and Confined)', 'Mythical'],
        ['Mew', 'Mythical'],
        ['Celebi', 'Mythical'],                
        ['Jirachi', 'Mythical'],
        ['Deoxys (Normal, Attack, Defense and Speed)', 'Mythical'],                
        ['Manaphy', 'Mythical'],
        ['Darkrai', 'Mythical'],
        ['Shaymin (Land and Sky)', 'Mythical'],
        ['Victini', 'Mythical'],
        ['Meloetta Aria', 'Mythical'],
        ['Genesect', 'Mythical'],
        ['Diancie (Default or Mega)', 'Mythical or mega evolution'],
        ['Volcanion', 'Mythical'],
        ['Magearna', 'Mythical'],
        ['Marshadow', 'Mythical'],
        ['Zeraora', 'Mythical'],
        ['Melmetal', 'Mythical'],
        ['Zarude', 'Mythical'],
        ['Keldeo', 'Mythical'],
        ['Slaking', 'Above 600 total base stats'],
        ['Dragonite', 'Above 600 total base stats'],
        ['Tyranitar (Default or Mega)', 'Above 600 total base stats or mega evolution'],
        ['Salamence (Default or Mega)', 'Above 600 total base stats or mega evolution'],
        ['Metagross (Default or Mega)', 'Above 600 total base stats or mega evolution'],
        ['Garchomp (Default or Mega)', 'Above 600 total base stats or mega evolution'],
        ['Hydreigon ', 'Above 600 total base stats'],
        ['Goodra ', 'Above 600 total base stats'],
        ['Kommo O ', 'Above 600 total base stats'],
        ['Venusaur Mega', 'Mega evolution'],
        ['Charizard Mega (X and Y)', 'Mega evolution'],
        ['Blastoise Mega', 'Mega evolution'],
        ['Alakazam Mega', 'Mega evolution'],
        ['Gengar Mega', 'Mega evolution'],
        ['Kangaskhan Mega', 'Mega evolution'],
        ['Pinsir Mega', 'Mega evolution'],
        ['Gyarados Mega', 'Mega evolution'],
        ['Aerodactyl Mega', 'Mega evolution'],
        ['Ampharos Mega', 'Mega evolution'],
        ['Scizor Mega', 'Mega evolution'],
        ['Heracross Mega', 'Mega evolution'],
        ['Houndoom Mega', 'Mega evolution'],
        ['Blaziken Mega', 'Mega evolution'],
        ['Gardevoir Mega', 'Mega evolution'],        
        ['Aggron Mega', 'Mega evolution'],        
        ['Manectric Mega', 'Mega evolution'],
        ['Banette Mega', 'Mega evolution'],
        ['Absol Mega', 'Mega evolution'],
        ['Lucario Mega', 'Mega evolution'],
        ['Abomasnow Mega', 'Mega evolution'],        
        ['Pidgeot Mega', 'Mega evolution'],
        ['Slowbro Mega', 'Mega evolution'],
        ['Steelix Mega', 'Mega evolution'],
        ['Sceptile Mega', 'Mega evolution'],
        ['Swampert Mega', 'Mega evolution'],        
        ['Sharpedo Mega', 'Mega evolution'],
        ['Camerupt Mega', 'Mega evolution'],
        ['Altaria Mega', 'Mega evolution'],
        ['Glalie Mega', 'Mega evolution'],
        ['Lopunny Mega', 'Mega evolution'],
        ['Gallade Mega', 'Mega evolution'],
        ['Audino Mega', 'Mega evolution'],
    ]
}

// Moves
const filteredMoves = {
    columns: ['Move', 'Filter'],
    rows: [
        ['G-Max Moves', 'Special type of moves'],
        ['Max Moves', 'Special type of moves'],
        ['Z-Moves', 'Special type of moves'],
        ['Struggle', 'Special type of move'],
        ['Gear Up', 'Filtered ability requirement'],
        ['Magnetic Flux', 'Filtered ability requirement'],
        ['Helping Hand', 'Unusable in single battles'],
        ['Aromatic Mist', 'Unusable in single battles'],
        ['Hold Hands', 'Unusable in single battles'],
        ['Coaching', 'Unusable in single battles'],        
        ['Spotlight', 'Unusable in single battles'],
        ['Quash', 'Unusable in single battles'],
        ['Decorate', 'Unusable in single battles'],
        ['Follow Me', 'Unusable in single battles'],
        ['Ally Switch', 'Unusable in single battles'],
        ['Heal Pulse', 'Unusable in single battles'],
        ['After You', 'Unusable in single battles'],        
        ['Happy Hour', 'Friendship based move'],
        ['Return', 'Friendship based move'],
        ['Frustration', 'Friendship based move'],
        ['Veevee Volley', 'Friendship based move'],
        ['Pika Papow', 'Friendship based move'],
        ['Happy Hour', 'Friendship based move'],
        ['Splash', 'No effect move'],
        ['Celebrate', 'No effect move'],        
    ]
}
const usabilityMoves = {
    columns: ['Move', 'Requirement'],
    rows: [
        ['Swallow', 'Moveset space to bundle with Stockpile'],
        ['Spit Up', 'Moveset space to bundle with Stockpile'],
        ['Stockpile', 'Moveset space to bundle with Swallow or Spit Up'],
        ['Aurora Veil', 'Moveset space to bundle with Hail'],        
        ['Hyperspace Fury', 'Pokémon options contain Hoopa Unbound'],
        ['Dark Void', 'Pokémon options contain Darkrai'],
        ['Aura Wheel', 'Pokémon options contain Morpeko'],
        ['Recycle', 'Item options will include a consumable item'],
        ['Natural Gift', 'Item options will include a berry'],
        ['Stuff Cheeks', 'Item options will include a berry'],
        ['Teatime', 'Item options will include a berry'],
        ['Belch', 'Item options will include a berry'],
        ['Hidden Power', 'Roll pokémon type from possible ones'],        
    ]
}

// Abilities
const filteredAbilities = {
    columns: ['Ability', 'Filter'],
    rows: [
        ['Illuminate', 'No effect in custom battles'],
        ['Ball Fetch', 'No effect in custom battles'],
        ['Honey Gather', 'No effect in custom battles'],
        ['Run Away', 'No effect in custom battles'],
        ['Plus', 'Unusable in single battles'],
        ['Minus', 'Unusable in single battles'],
        ['Battery', 'Unusable in single battles'],
        ['Healer', 'Unusable in single battles'],
        ['Friend Guard', 'Unusable in single battles'],
        ['Power of Alchemy', 'Unusable in single battles'],
        ['Power Spot', 'Unusable in single battles'],
        ['Receiver', 'Unusable in single battles'],
        ['Symbiosis', 'Unusable in single battles'],
        ['Telepathy', 'Unusable in single battles'],
        ['Curious Medicine', 'Unusable in single battles'],        
        ['Anticipation', 'Unusable with oposing team information'],
        ['Forewarn', 'Unusable with oposing team information'],
        ['Frisk', 'Unusable with oposing team information'],
        ['Schooling', 'Wishiwashi specific'],
    ]
}
const usabilityAbilities = {
    columns: ['Ability', 'Requirement'],
    rows: [
        ['Multitype', 'Pokémon options contain Arceus and item options will include a plate'],
        ['RKS System', 'Pokémon options contain Silvally and item options will include a memory'],
        ['Ice Face', 'Pokémon options contain Eiscue'],
        ['Hunger Switch', 'Pokémon options contain Morpeko and move options contain Aura Wheel'],
        ['Forecast', 'Pokémon options contain Castform and move or ability options contain Harsh Sunlight, Rain or Hail activation'],
        ['Flower Gift', 'Pokémon options contain Cherrim and move or ability options contain Harsh Sunlight activation'],
        ['Zen Mode', 'Pokémon options contain Darmanitan'],
        ['Battle Bond', 'Pokémon options contain Greninja'],
        ['Stance Change', "Pokémon options contain Aegislash and move options contain King's Shield"],
        ['Power Construct', 'Pokémon options contain Zygarde'],
        ['Shields Down', 'Pokémon options contain Minior'],
        ['Disguise', 'Pokémon options contain Mimikyu'],
        ['Gulp Missile', 'Pokémon options contain Cramorant and move options contain Surf or Dive'],
        ['Flower Veil', 'Pokémon options contain grass type pokémon'],
        ['Iron Fist', 'Move options contain punch moves'],
        ['Skill Link', 'Move options contain multistrike moves'],
        ['Reckless', 'Move options contain recoil or crash moves'],
        ['Rock Headed', 'Move options contain recoil moves'],
        ['Strong Jaw', 'Move options contain bite moves'],
        ['Mega Launcher', 'Move options contain aura or pulse moves'],
        ['Liquid Voice', 'Move options contain sound moves excluding Sparkling Aria'],
        ['Punk Rock', 'Move options contain sound moves'],
        ['Triage', 'Move options contain healing moves'],
        ['Tough Claws', 'Move options contain contact moves'],
        ['Unseen Fist', 'Move options contain contact moves'],
        ['Poison Touch', 'Move options contain contact moves'],
        ['Long Reach', 'Move options contain contact moves'],
        ['Flash Fire', 'Move options contain fire type moves'],
        ['Blaze', 'Move options contain fire type moves'],
        ['Overgrow', 'Move options contain grass type moves'],
        ['Torrent', 'Move options contain water type moves'],
        ['Swarm', 'Move options contain bug type moves'],
        ['Scrappy', 'Move options contain normal or fighting type moves'],
        ['Gale Wings', 'Move options contain flying type moves'],
        ['Dark Aura', 'Move options contain dark type moves'],
        ['Fairy Aura', 'Move options contain fairy type moves'],
        ['Steelworker', 'Move options contain steel type moves'],
        ['Steely Spirit', 'Move options contain steel type moves'],
        ['Transistor', 'Move options contain electric type moves'],
        ["Dragon's Maw", 'Move options contain dragon type moves'],
        ['Refrigerate', 'Move options contain normal type moves'],
        ['Pixilate', 'Move options contain normal type moves'],
        ['Galvanize', 'Move options contain normal type moves'],
        ['Aerilate', 'Move options contain normal type moves'],
        ['Truant', 'Move options contain bad ability moves*'],
        ['Stall', 'Move options contain bad ability moves*'],
        ['Klutz', 'Move options contain bad ability moves*'],
        ['Slow Start', 'Move options contain bad ability moves*'],
        ['Defeatist', 'Move options contain bad ability moves*'],
        ['Mimicry', 'Move or ability options contain terrain activation'],
        ['Grass Pelt', 'Move or ability options contain Grassy Terrain activation'],
        ['Surge Surfer', 'Move or ability options contain Electric Terrain activation'],
        ['Clorophyll', 'Move or ability options contain Harsh Sunlight activation'],        
        ['Leaf Guard', 'Move or ability options contain Harsh Sunlight activation'],        
        ['Solar Power', 'Move or ability options contain Harsh Sunlight activation'],        
        ['Hydration', 'Move or ability options contain Rain activation'],        
        ['Rain Dish', 'Move or ability options contain Rain activation'],        
        ['Swift Swim', 'Move or ability options contain Rain activation'],        
        ['Sand Veil', 'Move or ability options contain Sandstorm activation'],        
        ['Sand Rush', 'Move or ability options contain Sandstorm activation'],        
        ['Sand Force', 'Move or ability options contain Sandstorm activation and move options contain rock, ground or steel type moves'],        
        ['Ice Body', 'Move or ability options contain Hail activation'],        
        ['Snow Cloak', 'Move or ability options contain Hail activation'],        
        ['Slush Rush', 'Move or ability options contain Hail activation'],   
        ['Pickup', 'Item options will include a consumable item'],
        ['Unburden', 'Item options will include a consumable item'],
        ['Pickpocket', 'Item options will include a consumable item'],
        ['Magician', 'Item options will include a consumable item'],
        ['Cheek Pouch', 'Item options will include a berry'],     
        ['Gluttony', 'Item options will include a berry'],     
        ['Harvest', 'Item options will include a berry'],     
        ['Ripen', 'Item options will include a berry'],     
    ],
    references: [
        'Bad ability moves contain Entrainment and Skill Swap',
    ]
}

//Items
const filteredItems = {
    columns: ['Item', 'Filter'],
    rows: [
        ['Z-Crystals', 'Z-move related'],
        ['Mega Stones', 'Mega evolution related'],
        ['Deep Sea Tooth and Scale', 'Clamperl specific'],
        ['Light Ball', 'Pikachu specific'],
        ['Metal and Quick Powder', 'Ditto specific'], 
        ['Lucky Punch', 'Chansey specific'],
        ['Oran Berry', 'Notably weaker berry'],        
    ]
}
const usabilityItems = {
    columns: ['Item', 'Requirement'],
    rows: [
        ['Rusted Sword', 'Pokémon options contain Zacian'],
        ['Rusted Shield', 'Pokémon options contain Zamazenta'],
        ['Griseous Orb', 'Pokémon options contain Giratina and move options include dragon or ghost type moves'],
        ['Adamant Orb', 'Pokémon options contain Dialga and move options include dragon or steel type moves'],
        ['Lustrous Orb', 'Pokémon options contain Palkia and move options include dragon or water type moves'],
        ['Soul Dew', 'Pokémon options contain Latios or Latias and move options include dragon or psychic type moves'],
        ['Thick Club', "Pokémon options contain Sirfetch'd"],
        ['Stick', 'Pokémon options contain Marowak'],    
        ['Type Enhancement Items', 'Move options contain relevant pokémon type moves'],
        ['Gems', 'Move options contain relevant pokémon type moves'],        
        ['Power Herb', 'Move options contain charge moves excluding Sky Drop'],
        ['Grip Claw', 'Move options contain bind moves'],
        ['Binding Band', 'Move options contain bind moves'],
        ['Big Root', 'Move options contain drain moves'],
        ['Light Clay', 'Move options contain barrier moves'],        
        ['Throat Spray', 'Move options contain sound moves'],
        ['Room Service', 'Move options contain Trick Room'],        
        ['Terrain Extender', 'Move or ability options contain terrain activation'],
        ['Electric Seed', 'Move or ability options contain Electric Terrain activation'],
        ['Grassy Seed', 'Move or ability options contain Grassy Terrain activation'],
        ['Misty Seed', 'Move or ability options contain Misty Terrain activation'],
        ['Psychic Seed', 'Move or ability options contain Psychic Terrain activation'],
        ['Heat Rock', 'Move or ability options contain Harsh Sunlight activation excluding Desolate Land'],
        ['Damp Rock', 'Move or ability options contain Rain activation excluding Primordial Sea'],
        ['Icy Rock', 'Move or ability options contain Hail activation'],
        ['Smooth Rock', 'Move or ability options contain Sandstorm activation'],                        
        ['Black Sludge', 'Pokémon options contain poison type pokémon or move options contain bad item moves*'],
        ['Ring Target', 'Move options contain bad item moves*'],
        ['Iron Ball', 'Move options contain bad item moves*'],
        ['Lagging Tail', 'Move options contain bad item moves*'],
        ['Sticky Barb', 'Move options contain bad item moves*'],
        ['Full Incense', 'Move options contain bad item moves*'],
        ['Toxic Orb', 'Move or ability options contain bad item moves*, status orb moves*, status orb abilities*, Poison Heal or Toxic Boost'],
        ['Flame Orb', 'Move or ability options contain bad item moves*, status orb moves*, status orb abilities* or Flare Boost'],
        ['Plates', 'Move or ability options contain relevant pokémon type moves, Judgment or Multitype'],
        ['Memories', 'Move or ability options contain Multi Attack or RKS System'],
        ['Drives', 'Move options contain Techno Blast'],        
    ],
    references: [
        'Bad item moves contain Bestow, Fling, Switcheroo and Trick',
        'Status orb moves contain Facade and Psycho Shift',
        'Status orb abilities contain Guts, Marvel Scale, Quick Feet and Magic Guard'
    ]
}
    
export {
    filteredPokemon, topPokemon,
    filteredMoves, usabilityMoves,
    filteredAbilities, usabilityAbilities,
    filteredItems, usabilityItems
}